import React, { useMemo, useState } from "react";
import Highcharts, { SeriesLineOptions } from "highcharts";
import "highcharts/modules/accessibility";
import HighchartsReact from "highcharts-react-official";
import {
  FormControlLabel,
  List,
  ListItem,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { useProjectComparisonChartOptions } from "./helpers/useProjectComparisonChartOptions";
import { assert } from "../../../../_common/assert";
import {
  TimeRangeOption,
  TimeRangeOptionValue,
} from "./helpers/useTimeRangeOptions";
import {
  Checkbox,
  Chip,
  Typography,
  useSession,
  useTranslation,
} from "@lumar/shared";
import noDataGraphic from "./no-data-available.svg";
import { get } from "lodash";
import { CustomChartType, ReportTemplateUnit } from "../../../../graphql";
import { GENERATED_PALETTE } from "./helpers/generatedPalette";

const useStyles = makeStyles((theme) => ({
  highchartsContainer: {
    height: "100%",
    width: "100%",
  },
  noDataMessageContainer: {
    textAlign: "center",
    maxWidth: "264px",
    margin: "0 auto",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    lineHeight: theme.typography.pxToRem(21),
    marginTop: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}));

interface Props {
  allowChartUpdate: boolean;
  series: SeriesLineOptions[] | undefined;
  timeRangeOptions: TimeRangeOption[];
  timeRange: TimeRangeOptionValue;
  loading: boolean;
  chartType: CustomChartType;
  unit?: ReportTemplateUnit | null;
}

const CHART_LOCALHOST_KEY = "use-responsive-axis-on-chart";

// eslint-disable-next-line react/display-name
export const LineChart = React.forwardRef(
  (
    {
      allowChartUpdate,
      series,
      timeRangeOptions,
      timeRange,
      loading,
      chartType,
      unit = ReportTemplateUnit.UrLs,
    }: Props,
    ref: React.ForwardedRef<null>,
  ) => {
    const classes = useStyles();
    const {
      account: {
        subscription: { segmentationAvailable },
      },
    } = useSession();
    const theme = useTheme();
    const { t } = useTranslation("projectComparisonChart");
    const [disabledSeries, setDisabledSeries] = useState<string[]>([]);
    const [responsiveAxis, setResponsiveAxis] = useState<boolean>(
      localStorage.getItem(CHART_LOCALHOST_KEY) === "true",
    );
    const [hovered, setHovered] = useState<string | undefined>(undefined);
    const visualisationColors = useMemo(
      () => [
        theme.palette.pink[500],
        theme.palette.purple[500],
        theme.palette.blue[400],
        theme.palette.yellow[300],
        theme.palette.green[400],
        theme.palette.orange[500],
        theme.palette.lightBlue[400],
        theme.palette.teal[300],
        theme.palette.grey[400],
        theme.palette.rose[300],
        ...GENERATED_PALETTE,
      ],
      [theme],
    );

    const startingTimeInMilliseconds = useMemo(() => {
      const option = timeRangeOptions.find(
        (option) => option.value === timeRange,
      );
      assert(option);

      return option.startingTimeInMilliseconds;
    }, [timeRange, timeRangeOptions]);

    const timeCurrentlyInMilliseconds = useMemo(() => {
      return new Date().getTime();
      // NOTE: Want to recalculate current time every time theres a change (via a change to the
      // selected projects or selected metric) so that our x range doesn't become stale, stuck to the
      // time of the component being first mounted. - Saul.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const visibleSeries = useMemo(
      () =>
        series
          ?.filter((e) => !Boolean(disabledSeries.find((id) => id === e.id)))
          .map((e) => ({
            ...e,
            opacity: hovered && hovered !== e.id ? 0.3 : 1,
            lineWidth: hovered === e.id ? 2 : 1,
            zIndex: hovered === e.id ? 2 : 1,
          })),
      [series, disabledSeries, hovered],
    );

    const options = useProjectComparisonChartOptions({
      series: visibleSeries,
      timeCurrentlyInMilliseconds,
      startingTimeInMilliseconds,
      theme,
      responsiveAxis,
      maxY:
        chartType === CustomChartType.HealthScoreCrawlCompare ? 100 : undefined,
      type:
        chartType === CustomChartType.HealthScoreCrawlCompare
          ? "healthscore"
          : "monitor",
      unit: unit ?? ReportTemplateUnit.UrLs,
    });

    const hasNoDataToDisplay =
      !loading &&
      Boolean(series?.length) &&
      !!series?.every((s) => !s.data?.length);

    if (hasNoDataToDisplay) {
      return (
        <div className={classes.noDataMessageContainer}>
          <img
            src={noDataGraphic}
            alt="No data available"
            width={34}
            height={35}
          />
          <Typography variant="body2" className={classes.text}>
            {t("noDataMessage")}
          </Typography>
        </div>
      );
    }

    function writeResponsiveAxis(checked: boolean): void {
      localStorage.setItem(CHART_LOCALHOST_KEY, checked ? "true" : "false");
      setResponsiveAxis(checked);
    }

    return (
      <>
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              flex: "8 8 1270px",
              paddingTop: theme.spacing(2),
              display: "flex",
              flexFlow: "column",
              height: "100%",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <HighchartsReact
              ref={ref}
              allowChartUpdate={allowChartUpdate}
              highcharts={Highcharts}
              containerProps={{
                className: classes.highchartsContainer,
                "data-pendo": "monitor-project-comparison-chart",
              }}
              options={options}
            />
            <div
              style={{
                display: "flex",
                flexFlow: "row",
                gap: theme.spacing(1),
                marginTop: theme.spacing(1),
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={responsiveAxis}
                    size="medium"
                    title={t("responsive")}
                    inputProps={{
                      "aria-label": t("responsive"),
                    }}
                  />
                }
                label={
                  <Typography variant="caption">{t("responsive")}</Typography>
                }
                onChange={(_, checked) => {
                  writeResponsiveAxis(checked);
                }}
              />
            </div>
          </div>
          <List
            style={{
              minWidth: 250,
              overflowY: "auto",
              flex: "0 0 250px",
            }}
          >
            {series?.map((e) => {
              const disabledIndex = disabledSeries.findIndex(
                (id) => id === e.id,
              );
              const isDisabled = disabledIndex >= 0;
              return (
                <ListItem
                  key={e.id}
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    alignItems: "start",
                    width: "100%",
                  }}
                  onMouseEnter={() =>
                    isDisabled ? undefined : setHovered(e.id)
                  }
                  onMouseLeave={() => setHovered(undefined)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        borderRadius: 10,
                        backgroundColor: isDisabled
                          ? theme.palette.grey[300]
                          : visualisationColors[e.colorIndex ?? 0],
                        marginRight: theme.spacing(1),
                      }}
                    ></div>
                    <div
                      style={{
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setHovered(isDisabled ? e.id : undefined);
                        setDisabledSeries(
                          isDisabled
                            ? [
                                ...disabledSeries.slice(0, disabledIndex),
                                ...disabledSeries.slice(disabledIndex + 1),
                              ]
                            : [...disabledSeries, e.id ?? ""],
                        );
                      }}
                    >
                      <Typography
                        variant="caption"
                        style={{
                          color: isDisabled ? theme.palette.grey[300] : "black",
                        }}
                      >
                        {e.name}
                      </Typography>
                    </div>
                  </div>
                  {segmentationAvailable && (
                    <Chip
                      style={{
                        marginLeft: 10 + theme.spacing(1),
                        border: `1px solid ${theme.palette.grey[200]}`,
                        height: 20,
                      }}
                      label={
                        <Typography variant="subtitle4Medium">
                          {get(e as unknown, "segment")}
                        </Typography>
                      }
                      color="lightgrey"
                    />
                  )}
                </ListItem>
              );
            })}
          </List>
        </div>
      </>
    );
  },
);
