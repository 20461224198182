import { Snackbar, useTranslation, useApolloClient } from "@lumar/shared";
import {
  GetHealthScoreNotificationsQueryVariables,
  ThresholdType,
  useUpdateHealthScoreRulesAndThresholdsMutation,
} from "../../graphql";
import { useSnackbar } from "notistack";
import { ExtendedThresholdSettings, MonitorNotification } from "../types";
import { getErrorMessage } from "../../_common/utils/getErrorMessage";
import { useClearHealthScoreNotificationCache } from "../utils/useClearNotificationCache";
import {
  EditBaseRuleAndThresholdDialog,
  SaveRuleElement,
} from "./EditBaseRuleAndThresholdDialog";
import { updateHealthScoreNotificationsInCache } from "../utils/updateHealthScoreNotificationsInCache";

export interface EditHealthScoreRuleAndThresholdDialogProps {
  isOpen: boolean;
  onClose: () => void;
  notification: MonitorNotification;
  queryVariables?: GetHealthScoreNotificationsQueryVariables;
  isAdjustment: boolean;
}

export function EditHealthScoreRuleAndThresholdDialog({
  isOpen,
  onClose,
  notification,
  queryVariables,
  isAdjustment,
}: EditHealthScoreRuleAndThresholdDialogProps): JSX.Element {
  const apollo = useApolloClient();
  const clearCache = useClearHealthScoreNotificationCache();

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation(["alerts"]);

  const [updateRulesAndThresholds, { loading }] =
    useUpdateHealthScoreRulesAndThresholdsMutation({
      refetchQueries: [
        "GetHealthScoreNotifications",
        "HealthScoreNotificationsRequiresManualApprovalCount",
        "HealthScoreNotificationTotalCounts",
        "GetHealthScoreNotificationsCount",
      ],
      awaitRefetchQueries: true,
    });

  async function handleSave({
    severity,
    thresholdPredicate,
    absoluteThreshold,
    automaticThresholdAcceptanceWhenTestResultIsBetter:
      thresholdAcceptanceBetter,
    automaticThresholdAcceptanceWhenTestResultIsWorse: thresholdAcceptanceWorse,
  }: SaveRuleElement): Promise<void> {
    try {
      await updateRulesAndThresholds({
        variables: {
          rulesAndThresholds: {
            severity,
            thresholdPredicate,
            absoluteThreshold: absoluteThreshold / 100,
            healthScoreTestId: notification.test?.id,
            thresholdType: ThresholdType.Absolute,
            automaticThresholdAcceptanceWhenTestResultIsWorse:
              thresholdAcceptanceWorse,
            automaticThresholdAcceptanceWhenTestResultIsBetter:
              thresholdAcceptanceBetter,
          },
        },
      });
      handleRuleAndThresholdUpdate({
        thresholdPredicate,
        absoluteThreshold: absoluteThreshold,
        id: notification.test?.id,
        severity,
        automaticThresholdAcceptanceWhenTestResultIsWorse:
          thresholdAcceptanceWorse,
        automaticThresholdAcceptanceWhenTestResultIsBetter:
          thresholdAcceptanceBetter,
      });

      enqueueSnackbar(
        <Snackbar
          title={t("notificationsPage.thresholdUpdatedSuccessfully")}
          variant="success"
        />,
      );
    } catch (e) {
      enqueueSnackbar(
        <Snackbar
          title={t("notificationsPage.thresholdFailedToUpdate", {
            message: getErrorMessage(e),
          })}
          variant="error"
        />,
      );
    }

    onClose();
  }

  function handleRuleAndThresholdUpdate(test: ExtendedThresholdSettings): void {
    if (
      test.absoluteThreshold !== notification.absoluteThreshold ||
      test.automaticThresholdAcceptanceWhenTestResultIsBetter !==
        notification.automaticThresholdAcceptanceWhenTestResultIsBetter ||
      test.automaticThresholdAcceptanceWhenTestResultIsWorse !==
        notification.automaticThresholdAcceptanceWhenTestResultIsWorse ||
      test.thresholdPredicate !== test.thresholdPredicate
    ) {
      if (isAdjustment) clearCache();
      if (queryVariables)
        updateHealthScoreNotificationsInCache(
          apollo.cache,
          queryVariables,
          (nt) => {
            if (notification.id !== nt.id) return {};
            return {
              healthScoreTest: {
                ...nt.healthScoreTest,
                absoluteThreshold: test.absoluteThreshold / 100,
                automaticThresholdAcceptanceWhenTestResultIsBetter:
                  test.automaticThresholdAcceptanceWhenTestResultIsBetter,
                automaticThresholdAcceptanceWhenTestResultIsWorse:
                  test.automaticThresholdAcceptanceWhenTestResultIsWorse,
                thresholdPredicate: test.thresholdPredicate,
              },
            };
          },
        );
    }
  }

  return (
    <EditBaseRuleAndThresholdDialog
      handleSave={handleSave}
      notification={notification}
      isOpen={isOpen}
      onClose={onClose}
      validate={(n) => {
        const isUrlThresholdTooBigOrSmall = n < 0 || n > 100;
        return isUrlThresholdTooBigOrSmall
          ? Number(n) < 0
            ? t("alerts:minUrlsValidationMessage")
            : t("alerts:maxUrlsValidationMessage", {
                count: 100,
              })
          : undefined;
      }}
      loading={loading}
    />
  );
}
