import { ApolloCache } from "@lumar/shared";
import {
  GetHealthScoreNotificationsDocument,
  GetHealthScoreNotificationsQuery,
  GetHealthScoreNotificationsQueryVariables,
  HealthScoreTest,
  HealthScoreTestResult,
  Maybe,
  Segment,
} from "../../graphql";

export type PartialHealthScoreNotification = Omit<
  Partial<HealthScoreTestResult>,
  "healthScore" | "reportCategory" | "healthScoreTest" | "segment"
> & {
  healthScoreTest?: Maybe<
    Omit<Partial<HealthScoreTest>, "healthScoreTestResults">
  >;
} & {
  segment?: Maybe<Partial<Segment>>;
};

export function updateHealthScoreNotificationsInCache<T>(
  cache: ApolloCache<T>,
  queryVariables: GetHealthScoreNotificationsQueryVariables,
  callback: (
    tr: PartialHealthScoreNotification,
  ) => PartialHealthScoreNotification,
): void {
  const cachedData: GetHealthScoreNotificationsQuery | null = cache.readQuery({
    query: GetHealthScoreNotificationsDocument,
    variables: queryVariables,
  });
  if (!cachedData?.getAccount) return;

  cache.writeQuery({
    query: GetHealthScoreNotificationsDocument,
    variables: queryVariables,
    broadcast: true,
    data: {
      ...cachedData,
      getAccount: {
        ...cachedData.getAccount,
        notifications: {
          ...cachedData.getAccount.notifications,
          edges: cachedData.getAccount.notifications.edges.map((e) => {
            return {
              ...e,
              node: { ...e.node, ...callback(e.node) },
            };
          }),
        },
      },
    },
  });
}
