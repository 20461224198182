import { useTheme } from "@material-ui/core";
import Highcharts, {
  ColorString,
  DashStyleValue,
  GradientColorObject,
  GradientColorStopObject,
  PatternObject,
  SeriesOptionsType,
  XAxisPlotLinesOptions,
  YAxisPlotLinesOptions,
} from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "highcharts/modules/accessibility";
import { isEqual, isUndefined } from "lodash";
import React from "react";
import "./SparklinesMiniChart.css";

export interface SparklinesMiniChartData {
  values: Array<[number, number]>;
  color?: string;
  gradient?: [number, string][] | null;
  disableMarker?: boolean;
  dashStyle?: DashStyleValue | null;
  lineWidth?: number;
  zIndex?: number | null;
  type?: "area" | "column" | "spline";
}

export interface SparklinesMiniChartLineData {
  direction: "x" | "X" | "y" | "Y";
  value: number;
  color?: string;
  dashStyle?: DashStyleValue | null;
  lineWidth?: number;
  zIndex?: number | null;
}

type SparklinesDataTypeArray = (
  | SparklinesMiniChartData
  | SparklinesMiniChartLineData
  | [number, number][]
)[];

type SparklinesDataType =
  | SparklinesDataTypeArray
  | SparklinesMiniChartData
  | SparklinesMiniChartLineData;

type SparklinesSeriesDataType = (
  | SparklinesMiniChartData
  | [number, number][]
)[];

function isSparklinesMiniChartData(
  value: SparklinesDataType | [number, number][],
): value is SparklinesMiniChartData {
  return "values" in value && Array.isArray(value["values"]);
}

function isSparklinesMiniChartLineData(
  value: SparklinesDataType | [number, number][],
): value is SparklinesMiniChartLineData {
  return (
    "value" in value &&
    typeof value["value"] === "number" &&
    "direction" in value
  );
}
function isValidSeriesData(
  value: SparklinesDataType | [number, number][],
): boolean {
  return (
    (isSparklinesMiniChartData(value) &&
      !isSparklinesMiniChartLineData(value)) ||
    (Array.isArray(value) && Boolean(value.length) && Array.isArray(value[0]))
  );
}

export interface SparklinesMiniChartProps {
  height: number;
  width?: number | string | null;
  data: SparklinesDataType;
  allowChartUpdate?: boolean;
  minX?: number;
  maxX?: number;
  minY?: number;
  maxY?: number;
}

function getMaximumYValue(data?: SparklinesDataTypeArray | null): number {
  if (!data) {
    return 0;
  }

  const yValues = data.map((e) => {
    if (isSparklinesMiniChartData(e)) {
      return Math.max(...e.values.map(([, y]) => y));
    } else if (isSparklinesMiniChartLineData(e)) {
      return e.value;
    }
    if (!Array.isArray(e)) return 0;
    return Math.max(...e.map(([, y]) => y));
  });

  const maxDataValue = Math.max(...yValues);

  return maxDataValue;
}

export const SparklinesMiniChart = React.memo(
  function SparklinesMiniChart({
    height,
    width,
    data,
    minX,
    maxX,
    minY,
    maxY,
    allowChartUpdate = false,
  }: SparklinesMiniChartProps): JSX.Element {
    const theme = useTheme();
    // Note: this is required because highcharts does not sort data by default (for performance reasons)
    // eslint-disable-next-line fp/no-mutating-methods
    const chartData =
      isSparklinesMiniChartData(data) || isSparklinesMiniChartLineData(data)
        ? [data]
        : data;
    const series = chartData.filter(
      (e): e is SparklinesSeriesDataType[number] => isValidSeriesData(e),
    );
    const plotsX = chartData.filter(
      (e): e is SparklinesMiniChartLineData =>
        isSparklinesMiniChartLineData(e) && e.direction.toUpperCase() === "X",
    );
    const plotsY = chartData.filter(
      (e): e is SparklinesMiniChartLineData =>
        isSparklinesMiniChartLineData(e) && e.direction.toUpperCase() === "Y",
    );

    function getFillColor(
      data: SparklinesMiniChartData | [number, number][],
    ): ColorString | GradientColorObject | PatternObject {
      if (isSparklinesMiniChartData(data)) {
        if (
          data.gradient === null ||
          (data.gradient && data.gradient.length === 0)
        )
          return "transparent";
        if (!data.gradient)
          return {
            linearGradient: { x1: 0, x2: 0, y1: 0.1, y2: 1 },
            stops: [
              [0, theme.palette.grey[100]] as GradientColorStopObject,
              [1, theme.palette.grey[100]],
            ],
          };
        return {
          linearGradient: { x1: 0, x2: 0, y1: 0.1, y2: 1 },
          stops: data.gradient.map((e) => [e[0], e[1]] as [number, string]),
        };
      }
      return {
        linearGradient: { x1: 0, x2: 0, y1: 0.1, y2: 1 },
        stops: [
          [0, theme.palette.grey[100]] as GradientColorStopObject,
          [1, theme.palette.grey[100]],
        ],
      };
    }

    const options: Highcharts.Options = {
      accessibility: {
        keyboardNavigation: {
          enabled: false,
        },
      },
      chart: {
        reflow: false,
        type: "area",
        animation: false,
        height,
        width,
        backgroundColor: "transparent",
        spacing: [0, 0, 0, 0],
        borderWidth: 0,
        margin: [0, 2, 2, 2],
      },
      colors: [theme.palette.grey[600]],
      plotOptions: {
        series: {
          animation: false,
          lineWidth: 1,
          enableMouseTracking: false,
          accessibility: {
            enabled: false,
            keyboardNavigation: {
              enabled: false,
            },
          },
        },
        area: {
          fillColor: "transparent",
          lineWidth: 1,
          animation: false,
          enableMouseTracking: false,
        },
        spline: {
          lineWidth: 1,
          animation: false,
          enableMouseTracking: false,
        },
        column: {
          animation: false,
          enableMouseTracking: false,
        },
      },
      series: series
        .filter((e) => isValidSeriesData(e))
        .map((cData) => {
          const isSparklineData = isSparklinesMiniChartData(cData);
          // eslint-disable-next-line fp/no-mutating-methods
          const sortedData = (
            isSparklineData ? [...cData.values] : [...cData]
          ).sort((a, b) => a[0] - b[0]);

          if (isSparklineData) {
            const color = cData.color ?? theme.palette.grey[600];
            const disableMarker = cData.disableMarker;
            const dashStyle = cData.dashStyle ?? "Solid";
            const lineWidth = cData.lineWidth ?? 1;
            const zIndex = !isUndefined(cData.zIndex) ? cData.zIndex : 1;

            return {
              animation: false,
              enableMouseTracking: false,
              data: sortedData,
              color: color,
              type: cData.type ?? "area",
              fillColor: getFillColor(cData),
              dashStyle: dashStyle,
              marker: {
                enabled: !disableMarker,
                radius: 2,
              },
              lineWidth: lineWidth,
              zIndex: zIndex,
            } as SeriesOptionsType;
          }
          return {
            animation: false,
            enableMouseTracking: false,
            color: theme.palette.grey[600],
            data: sortedData,
            type: "area",
            dashStyle: "Solid",
            fillColor: getFillColor(cData),
            marker: {
              enabled: true,
              radius: 2,
            },
          };
        }),
      title: {
        text: undefined,
      },
      xAxis: {
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        visible: false,
        lineColor: "#FFFFFF",
        min: minX,
        max: maxX,
        plotLines: plotsX.map(
          (e): XAxisPlotLinesOptions => ({
            color: e.color ?? theme.palette.red[500],
            dashStyle: e.dashStyle ?? "Dash",
            width: e.lineWidth ?? 1,
            value: e.value,
            zIndex: e.zIndex ?? 100,
          }),
        ),
      },
      yAxis: {
        lineColor: "#FFFFFF",
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        max: maxY ? maxY + 2 : getMaximumYValue(chartData) + 2,
        min: minY,
        plotLines: plotsY.map(
          (e): YAxisPlotLinesOptions => ({
            color: e.color ?? theme.palette.red[500],
            dashStyle: e.dashStyle ?? "Dash",
            width: e.lineWidth ?? 1,
            value: e.value,
            zIndex: e.zIndex ?? 100,
          }),
        ),
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
    };

    return (
      <>
        {data && chartData.length && (
          <div data-testid="sparkline-mini-chart">
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              allowChartUpdate={allowChartUpdate}
            />
          </div>
        )}
      </>
    );
  },
  (prev, next) =>
    prev.height === next.height &&
    prev.width === next.width &&
    isEqual(prev.data, next.data) &&
    prev.allowChartUpdate === next.allowChartUpdate &&
    prev.minX === next.minX &&
    prev.maxX === next.maxX,
);
